import userActions from "./actions";

const initialState = {
    selectedUserPayment: {},
    usersError: '',
    usersSuccess: '',
    isLoadingPaymentSession: false,
    isLoadingUserPayment: false,
    modalActive: false
};

export default function usersReducer(state = initialState, action) {
    switch (action.type) {
        case userActions.FIND_USER_PAYMENT_START:
            return {
                ...state,
                ...action.payload,
                isLoadingUserPayment: true
            };
        case userActions.FIND_USER_PAYMENT_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isLoadingUserPayment: false
            };
        case userActions.FIND_USER_PAYMENT_FAILED:
            return {
                ...state,
                ...action.payload,
                isLoadingUserPayment: false
            };
        case userActions.CHECKOUT_SESSION_START:
            return {
                ...state,
                ...action.payload,
                isLoadingPaymentSession: true
            };
        case userActions.CHECKOUT_SESSION_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isLoadingPaymentSession: false
            };
        case userActions.CHECKOUT_SESSION_FAILED:
            return {
                ...state,
                ...action.payload,
                isLoadingPaymentSession: false
            };
        case userActions.SUBSCRIPTION_SESSION_START:
            return {
                ...state,
                ...action.payload,
                isLoadingPaymentSession: true
            };
        case userActions.SUBSCRIPTION_SESSION_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isLoadingPaymentSession: false
            };
        case userActions.SUBSCRIPTION_SESSION_FAILED:
            return {
                ...state,
                ...action.payload,
                isLoadingPaymentSession: false
            };
        default:
            return state;
    }
}