import { combineReducers } from "redux";
import App from "@iso/redux/app/reducer";
import Auth from "@iso/redux/auth/reducer";
import DynamicChartComponent from "@iso/redux/dynamicEchart/reducer";
import ThemeSwitcher from "@iso/redux/themeSwitcher/reducer";
import LanguageSwitcher from "@iso/redux/languageSwitcher/reducer";
import drawer from "@iso/redux/drawer/reducer";
import modal from "@iso/redux/modal/reducer";
import projects from "@iso/redux/projects/reducer";
import contractors from "@iso/redux/contractors/reducer";
import networkContractors from "@iso/redux/networkContractors/reducer";
import userCategories from "@iso/redux/userCategories/reducer";
import users from "@iso/redux/users/reducer";
import profiles from "@iso/redux/profiles/reducer";
import projectItems from "@iso/redux/projectitems/reducer";
import projectPayments from "@iso/redux/projectPayments/reducer";
import changeOrders from "@iso/redux/changeOrders/reducer";
import projectWizard from '@iso/redux/projectWizard/reducer';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  DynamicChartComponent,
  modal,
  drawer,
  projects,
  contractors,
  networkContractors,
  userCategories,
  users,
  profiles,
  projectItems,
  projectPayments,
  changeOrders,
  projectWizard
});
