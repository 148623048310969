import oneTimePaymentImage from '@iso/assets/images/OneTimePaymentImage.jpg';
import subscriptionPaymentImage from '@iso/assets/images/SubscriptionPaymentImage.jpg';

export const PAYMENT_STATUSES = {
    PAID: 'paid',
    PENDING: 'pending',
};

export const PAYMENT_PAID = {
    TRUE: 'true',
    FALSE: 'false'
}

export const SERVICES = {
    REMODEL_AND_BUILDERS: 'Remodel & Builders',
    ELECTRICAL_AND_HOME_SYSTEMS: 'Electrical & Home Systems (TV - Alarms- Automation- Other)',
    LANDSCAPING: 'Landscaping',
    OUTDOOR_MAINTENANCE: 'Outdoor Maintenance (Cleaning - Lawn Care - Snow Removal)',
    PLUMBING: 'Plumbing',
    FLOORS: 'Floors',
    FENCING: 'Fencing',
    PEST_ANIMAL: 'Pest/Animal',
    CONCRETE: 'Concrete',
    PAINTING_WALLPAPER_AND_STUCCO: 'Painting - Wallpaper & Stucco',
    TREE_SERVICE: 'Tree Service',
    HVAC: 'HVAC',
    MOVING: 'Moving',
    HANDYMEN: 'Handymen',
    ENVIRONMENTAL_TESTING_REMEDIATION_RESTORATION: 'Environmental Testing - Remediation - Restoration',
    MASONRY: 'Masonry',
    ROOFING_SIDING: 'Roofing & Siding',
    CLEANING: 'Cleaning',
    DRYWALL_PLASTER: 'Drywall & Plaster',
    PATIO_DECK: 'Patio / Deck',
    WINDOWS_AND_DOORS: 'Windows and Doors'
};

export const FIELD_TYPES_DEFINITION = {
    TEXT: 'text',
    TEXT_AREA: 'textArea',
    NUMBER: 'number',
    DATE: 'date',
    SWITCH: 'switch',
    SELECT: 'select',
    FLEXIBLE_SELECT: 'flexibleSelect',
    COST_STRUCTURE: 'costStructure',
    TAG_SELECT: 'tagSelect',
    RADIO: 'radio',
    FILE_UPLOAD: 'fileUpload'
};

export const CATEGORY_TYPES = {
    CONTRACTORS: 'contractors',
    OUT_OF_POCKET: 'out-of-pocket'
}

export const PROJECT_TABS = {
    PLANNED_BUDGET: 'planned-budget',
    PAYMENTS_MADE: 'payments-made',
    PROJECT_INFO: 'project-info',
    ESTIMATES_COMPARISON: 'estimates-comparison'
}

const PROJECT_STATUSES = [
    { label: 'Proposal', value: 'proposal' },
    { label: 'Proposal-sent', value: 'proposal-sent' },
    { label: 'Execution', value: 'execution' },
    { label: 'Completed', value: 'completed' },
    { label: 'Cancelled', value: 'cancelled' },
];

const STANDARDS = [
    { label: 'Basic/Standard', value: 'LOW' },
    { label: 'Mid-Range', value: 'MEDIUM' },
    { label: 'High-End/Luxury', value: 'HIGH' },
];

const STANDARDS_DESCRIPTION = {
    LOW: 'This level includes standard or entry-level finishes that are more budget-friendly. It typically encompasses basic materials, fixtures, and appliances with minimal customization or high-end features.',
    MEDIUM: 'This level offers a balance between cost and quality. It involves upgrading materials, fixtures, and appliances to a mid-range standard, providing improved aesthetics and functionality compared to the basic level.',
    HIGH: 'This level represents premium or luxury finishes, materials, fixtures, and appliances. It includes high-quality, upscale options, often customized or tailored to specific design preferences, providing a luxurious and sophisticated look and feel.',
};

const PROJECT_PURPOSES = [
    'Estimate cost of my renovation',
    'Find a contractor',
    'Compare and select contractors',
    'Track costs for my renovation',
    'Manage timelines of my renovation',
    'Better communicate with my contractor',
    'Other',
];

export const PAYMENT_PLANS_OPTIONS = {
    ONE_TIME: 'ONE_TIME',
    SUBSCRIPTION: 'SUBSCRIPTION'
}

export const PAYMENT_PLANS = {
    ONE_TIME: {
        contentTitle: 'UNLIMITED PLANNING SUPPORT (One Time Payment)',
        description: 'GET A HEAD START ON YOUR RENOVATION: CALCULATE YOUR RENOVATION COSTS (Unlimited estimates tailored to your needs), NEGOTIATE EFFECTIVELY (Contractor Quote Aggregation and Comparison), SELECT THE RIGHT CONTRACTOR (Get help finding Contractors in your area)',
        title: 'Choose Plan',
        image: oneTimePaymentImage
    },
    SUBSCRIPTION: {
        contentTitle: 'UNLIMITED MANAGEMENT SUPPORT (Subscription)',
        description: 'KEEP YOUR BUDGET UNDER CONTROL: TRACK YOUR RENOVATION EXPENSES (Monitor costs, estimate overruns and change orders using templates), STORE ALL YOUR PAYMENTS, INVOICES (and other documents in a single repository), AVIOD  MISUNDERSTANDINGS (REAL TIME FINANCIAL UPDATES to Contractors and Pros)',
        title: 'Choose Plan',
        image: subscriptionPaymentImage
    }
};

export const COST_TYPES = {
    MATERIALS: 'materials',
    LABOR: 'labor',
};

export const DEFAULT_CATEGORY = 'Other';

export const COST_TYPE_LABELS = [
    { label: 'Out-of-pocket', value: COST_TYPES.MATERIALS },
    { label: 'Contractor', value: COST_TYPES.LABOR },
];

export const getAllPaymentStatuses = () => {
    return Object.values(PAYMENT_STATUSES);
}

export const getAllServices = () => {
    return Object.values(SERVICES);
}

export const getAllPaidCheckboxes = () => {
    return Object.values(PAYMENT_PAID);
}

export const getInitialProjectStatus = () => {
    return PROJECT_STATUSES[0].value;
};

export const getProjectStatusesForEmailHO = () => {
    return PROJECT_STATUSES.filter((x) => ['proposal-sent', 'execution'].includes(x.value)).map(
        (x) => x.value
    );
};

export const getProjectStatuses = () => {
    return PROJECT_STATUSES;
};

export const getLabelProjectStatuses = () => {
    return PROJECT_STATUSES.map((x) => x.label);
};

export const getProjectStandards = () => {
    return STANDARDS;
}

export const getInitialProjectStandard = () => {
    return STANDARDS[0].value;
}

export const getDescriptionBasedOnStandard = (standard) => {
    return STANDARDS_DESCRIPTION[standard];
}


export const getProjectPurposes = () => {
    return PROJECT_PURPOSES;
}

export const getInitialProjectPurpose = () => {
    return PROJECT_PURPOSES[0];
}

export const defaultOwnerEmailAddress = 'draftProject@remodelum.com';
export const sampleProjectEmailAddress = 'sampleproject@remodelum.com';
export const automaticEstimationEmailAddress = 'info@remodelum.com';
