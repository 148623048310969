import contractorActions from "./actions";

const initialState = {
  contractorsList: {},
  contractorsError: '',
  contractorsSuccess: '',
  isLoadingContractors: false,
  modalActive: false
};

export default function contractorsReducer(state = initialState, action) {
  switch (action.type) {
    case contractorActions.LOAD_CONTRACTORS_OF_PROJECT_START:
      return { 
        ...state, 
        ...action.payload, 
        isLoadingContractors: true,
        modalActive: false, 
      };
    case contractorActions.LOAD_CONTRACTORS_OF_PROJECT_SUCCESS:
      return { 
        ...state, 
        ...action.payload,
        isLoadingContractors: false
        };
    case contractorActions.LOAD_CONTRACTORS_OF_PROJECT_FAILED:
        return { 
          ...state, 
          ...action.payload,
          isLoadingContractors: false
         };
    case contractorActions.CREATE_CONTRACTOR_START:
      return {
          ...state,
          ...action.payload,
          isLoadingContractors: true,
          modalActive: !state.modalActive,
      };     
    case contractorActions.CREATE_CONTRACTOR_SUCCESS:
        return { ...state, ...action.payload, isLoadingContractors: false };
    case contractorActions.CREATE_CONTRACTOR_FAILED:
        return { ...state, ...action.payload, isLoadingContractors: false };
    case contractorActions.EDIT_CONTRACTOR_START:
      return { ...state, ...action.payload, modalActive: !state.modalActive, isLoadingContractors: true };
    case contractorActions.EDIT_CONTRACTOR_SUCCESS:
      return { ...state, ...action.payload, isLoadingContractors: false };
    case contractorActions.EDIT_CONTRACTOR_FAILED:
      return { ...state, ...action.payload, isLoadingContractors: false };
    case contractorActions.DELETE_CONTRACTOR_START:
      return { ...state, ...action.payload };
    case contractorActions.DELETE_CONTRACTOR_SUCCESS:
        return { ...state, ...action.payload };
    case contractorActions.DELETE_CONTRACTOR_FAILED:
        return { ...state, ...action.payload };
    case contractorActions.MAKE_COMPARATIVE_CONTRACTOR_START:
      return { ...state, ...action.payload };
    case contractorActions.MAKE_COMPARATIVE_CONTRACTOR_SUCCESS:
      return { ...state, ...action.payload };
    case contractorActions.MAKE_COMPARATIVE_CONTRACTOR_FAILED:
      return { ...state, ...action.payload };
    case contractorActions.FIND_CONTRACTOR_START:
      return { ...state, ...action.payload };
    case contractorActions.FIND_CONTRACTOR_SUCCESS:
      return { ...state, ...action.payload };
    case contractorActions.FIND_CONTRACTOR_FAILED:
      return { ...state, ...action.payload };
    case contractorActions.CLEAR_CONTRACTORS_FLAGS:
        return { ...state, ...action.payload };
    case contractorActions.SYNC_CATEGORY_START:
      return { ...state, ...action.payload };
    case contractorActions.SYNC_CATEGORY_SUCCESS:
      return { ...state, ...action.payload };
    case contractorActions.SYNC_CATEGORY_FAILED:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}