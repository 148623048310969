const userActions = {
    //FIND_USER_PAYMENT
    FIND_USER_PAYMENT_START: 'FIND_USER_PAYMENT_START',
    FIND_USER_PAYMENT_SUCCESS: 'FIND_USER_PAYMENT_SUCCESS',
    FIND_USER_PAYMENT_FAILED: 'FIND_USER_PAYMENT_FAILED',

    //CHECKOUT_SESSION
    CHECKOUT_SESSION_START: 'CHECKOUT_SESSION_START',
    CHECKOUT_SESSION_SUCCESS: 'CHECKOUT_SESSION_SUCCESS',
    CHECKOUT_SESSION_FAILED: 'CHECKOUT_SESSION_FAILED',

    //SUBSCRIPTION_SESSION
    SUBSCRIPTION_SESSION_START: 'SUBSCRIPTION_SESSION_START',
    SUBSCRIPTION_SESSION_SUCCESS: 'SUBSCRIPTION_SESSION_SUCCESS',
    SUBSCRIPTION_SESSION_FAILED: 'SUBSCRIPTION_SESSION_FAILED',

    // User Actions
    createFindUserPaymentStart: (payload) => {
        return {
            type: userActions.FIND_USER_PAYMENT_START,
            payload: payload
        };
    },
    createFindUserPaymentSuccess: (usersSuccess) => {
        return {
            type: userActions.FIND_USER_PAYMENT_SUCCESS,
            payload: usersSuccess
        };
    },
    createFindUserPaymentFailed: (usersError) => {
        return {
            type: userActions.FIND_USER_PAYMENT_FAILED,
            payload: usersError
        };
    },
    createCheckoutSessionStart: (payload) => {
        return {
            type: userActions.CHECKOUT_SESSION_START,
            payload: payload
        };
    },
    createCheckoutSessionSuccess: (usersSuccess) => {
        return {
            type: userActions.CHECKOUT_SESSION_SUCCESS,
            payload: usersSuccess
        };
    },
    createCheckoutSessionFailed: (usersError) => {
        return {
            type: userActions.CHECKOUT_SESSION_FAILED,
            payload: usersError
        };
    },
    createSubscriptionSessionStart: (payload) => {
        return {
            type: userActions.SUBSCRIPTION_SESSION_START,
            payload: payload
        };
    },
    createSubscriptionSessionSuccess: (usersSuccess) => {
        return {
            type: userActions.SUBSCRIPTION_SESSION_SUCCESS,
            payload: usersSuccess
        };
    },
    createSubscriptionSessionFailed: (usersError) => {
        return {
            type: userActions.SUBSCRIPTION_SESSION_FAILED,
            payload: usersError
        };
    }
};

export default userActions;