import { PAYMENT_STATUSES } from '@iso/lib/remod-helpers/globalEnumsAndConsts';
import cloneDeep from 'lodash/cloneDeep';
import { COST_TYPES, automaticEstimationEmailAddress } from '@iso/lib/remod-helpers/globalEnumsAndConsts';
import { convertObjectCollectionToArrayForTreeTable } from '@iso/lib/remod-helpers/globalHelpers';
import {
    isLoggedInUserAContractor,
    isLoggedInUserAHomeOwner,
    getLoggedInUserEmail,
    getLoggedInUser,
} from '@iso/lib/remod-helpers/localStorage';

export const prepareProjectItemsAssignedToPayments = (projectItems) => {
    const mapResult = new Map();

    Object.keys(projectItems || {})
        .filter((id) => projectItems[id].contractorId === '')
        .filter((id) => projectItems[id].costType === COST_TYPES.MATERIALS)
        .forEach((id) => {
            // Determine the appropriate key for grouping, using an empty string for items without a valid paymentId
            const paymentIdKey = projectItems[id].paymentId || '';

            // Retrieve the current group of items for this paymentId, or initialize it if it doesn't exist
            const currentGroup = mapResult.get(paymentIdKey) || [];

            // Append the current item to its group
            const updatedGroup = currentGroup.concat([
                {
                    key: id,
                    name: projectItems[id].name,
                    category: projectItems[id].categoryId,
                    isProjectItem: true,
                    cost: projectItems[id].cost,
                    selected: false,
                },
            ]);

            // Update the map with the newly appended item group
            mapResult.set(paymentIdKey, updatedGroup);
        });

    // Ensure there's a default group for items with no paymentId (this might be redundant depending on your data handling but keeps the logic explicit)
    if (!mapResult.has('')) {
        mapResult.set('', []);
    }

    return mapResult;
};

export const prepareContractorsEstimateWithPayments = (contractors, payments, projectItems) => {
    const treeResult = [{ key: '', children: [] }];
    const filteredContractors = filterContractorsDependOnRole(contractors, false);
    let contractorsArray = convertObjectCollectionToArrayForTreeTable(filteredContractors);

    if (isLoggedInUserAContractor()) {
        const loggedInUser = getLoggedInUser();
        const contractorDisplayName = `You (${loggedInUser.email})`;
        contractorsArray = [
            {
                key: 'you',
                email: loggedInUser.email,
                name: contractorDisplayName,
                isContractor: true,
            },
        ];
        contractors = {
            you: { email: loggedInUser.email, name: contractorDisplayName, isContractor: true, key: 'you' },
        };
    }

    let filtered =
        payments &&
        Object.keys(payments)
            .filter((key) => payments[key].contractorId !== '')
            .reduce((obj, key) => {
                obj[key] = payments[key];
                obj[key].amount = payments[key].value;
                obj[key].source = payments[key].paymentSource;
                obj[key].isProjectPayment = true;
                obj[key].date = payments[key].paidAt;
                obj[key].paid = obj[key].status === PAYMENT_STATUSES.PAID ? obj[key].value : 0;
                return obj;
            }, {});
    const contractorsResult = filtered
        ? processChildrenForContractors(filtered, 'contractorId', () => sortByDate())
        : new Map();
    contractors &&
        contractorsArray.forEach((contractor) => {
            const element = contractors[contractor.key];
            if (element === undefined) {
                return;
            }
            const atLeastOneProjectItemAdedToBudget = Object.keys(projectItems).filter((key) => projectItems[key].contractorId === element.email).length > 0 ?
                Object.keys(projectItems)
                    .filter((key) => projectItems[key].contractorId === element.email)
                    .filter((key) => projectItems[key].accepted === true).length > 0
                : true;
            if (!atLeastOneProjectItemAdedToBudget) {
                return;
            }
            element.key = contractor.key;
            element.isContractor = true;
            const cost = Object.keys(projectItems)
                .filter((key) => projectItems[key].contractorId === element.email)
                .map((key) => projectItems[key].cost)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

            if (
                contractorsResult.has(element.email) &&
                contractorsResult.get(element.email).length > 0
            ) {
                element.children = contractorsResult.get(element.email)
                    .map(obj => ({ ...obj, contractorInvited: contractor.contractorInvited, userContractorExists: contractor.userContractorExists })); 
            } else {
                delete element['children'];
                element.hasNoChildren = true;
            }
            element.cost = cost;
            element.amount =
                element.children?.length > 0
                    ? element.children
                          .map((x) => x.amount)
                          .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                    : 0;
            element.paid =
                element.children?.length > 0
                    ? element.children
                          .filter((x) => x.status === PAYMENT_STATUSES.PAID)
                          .map((x) => x.value)
                          .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                    : 0;
            treeResult[0].children.push(element);
        });

    return {
        tree: treeResult[0].children,
        list: filtered,
        contractorsAssigned: contractorsArray.length > 0,
        totalSum: treeResult[0].children
            .map((x) => x.paid)
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
    };
};

export const preparePaymentsForNonProfessionalServices = (payments) => {
    const treeResult = [{ key: '', children: [] }];
    if (payments) {
        let filtered = Object.keys(payments)
            .filter((key) => payments[key].contractorId === '')
            .reduce((obj, key) => {
                obj[key] = payments[key];
                obj[key].amount = payments[key].value;
                obj[key].isProjectPayment = true;
                obj[key].source = payments[key].paymentSource;
                obj[key].date = payments[key].paidAt;
                return obj;
              }, {});
        Object.keys(filtered)
            .map((id) => {
                treeResult[0].children.push({ ...filtered[id], key: id}); 
        });
    }
    if (treeResult[0].children.length > 0) {
        treeResult[0].children.sort(sortByDate());
    }
    return {tree: treeResult[0].children, list: payments, totalSum: treeResult[0].children.map(x => x.amount).reduce((accumulator, currentValue) => accumulator + currentValue, 0) };
}

export const prepareEstimationWithCategories = (projectItems, userCategories, categoryType, contractorId = '') => {
    const treeResult = [{ key: '', children: [] }];
    let processedCategories = [];
    if (userCategories) {
        let filteredCategories = Object.keys(userCategories)
            .filter((key) => userCategories[key].type === categoryType)
            .reduce((obj, key) => {
                obj[key] = userCategories[key];
                obj[key].isCategory = true;
                obj[key].key = key;
                obj[key].email = contractorId;
                return obj;
            }, {});
        processedCategories = convertObjectCollectionToArrayForTreeTable(filteredCategories);
        if (projectItems) {
            let filtered = Object.keys(projectItems)
                .filter((key) => projectItems[key].contractorId === contractorId && projectItems[key]?.costType === COST_TYPES.LABOR && projectItems[key].accepted === false)
                .reduce((obj, key) => {
                    obj[key] = projectItems[key];
                    obj[key].isProjectItem = true;
                    return obj;
                }, {});
            const categoriesWithProjectItems = processChildrenForCategories(filtered, undefined, contractorId);
            const projectItemCategories = categoriesWithProjectItems.map(x => x.key);
            processedCategories = processedCategories.filter(x => !projectItemCategories.includes(x.name));
            processedCategories = processedCategories.concat(categoriesWithProjectItems);
        }
        if (processedCategories.length > 0) {
            processedCategories.sort(sortByFirstLetter());
            treeResult[0].children = processedCategories;
        }
    }
    return { tree: treeResult[0].children, list: userCategories };
}

export const prepareCategoriesEstimateWithScope = (projectItems, payments) => {
    const treeResult = [{ key: '', children: [] }];
    if (projectItems) {
        let filtered = Object.keys(projectItems)
            .filter((key) => projectItems[key].contractorId === '' && projectItems[key]?.costType === COST_TYPES.MATERIALS && projectItems[key].completed === true && projectItems[key].accepted === true)
            .reduce((obj, key) => {
                obj[key] = projectItems[key];
                const hasPaid = Object.keys(payments).filter((paymentKey) => paymentKey === projectItems[key].paymentId && payments[paymentKey].status === PAYMENT_STATUSES.PAID).length > 0 ? true : false;
                obj[key].hasPaid = hasPaid;
                obj[key].isProjectItem = true;
                obj[key].balance = hasPaid ? 0 : obj[key].cost;
                return obj;
              }, {});
        const result = processChildrenForCategories(filtered);
        if (result.length > 0) {
            treeResult[0].children = result;
        }
    }
    return {tree: treeResult[0].children, list: projectItems, totalSum: treeResult[0].children.map(x => x.cost).reduce((accumulator, currentValue) => accumulator + currentValue, 0) };
}

const isAutomaticEstimation = (contractor) => {
    return contractor.email === automaticEstimationEmailAddress;
}

const filterContractorsDependOnRole = (contractors, considerContractorsWithoutEstimation = true) => {
    let filteredContractors = {};
    if (isLoggedInUserAContractor()) {
        contractors && Object.keys(contractors)
            .filter(key => contractors[key].email === getLoggedInUserEmail())
            .map(key => {
                filteredContractors[key] = contractors[key];
            });
    } else if (isLoggedInUserAHomeOwner()) {
        contractors && Object.keys(contractors)
            .filter(key => considerContractorsWithoutEstimation || isAutomaticEstimation(contractors[key]) ? contractors[key].contractorWithEstimationLink === false : true)
            .map(key => {
                filteredContractors[key] = contractors[key];
            });
    }
    if (!filteredContractors || Object.keys(filteredContractors).length === 0 || filteredContractors.length === 0) {
        if (isLoggedInUserAContractor()) {
            const contractorsOwnDisplayName = `You (${getLoggedInUserEmail()})`;
            filteredContractors[contractorsOwnDisplayName] = {
                name: contractorsOwnDisplayName,
                email: getLoggedInUserEmail(),
                isContractor: true,
            };
        } else {
            filteredContractors.notAssigned = {
                name: 'No contractor assigned',
                email: '',
                isContractor: true,
                comparative: true
            };
        }
    }
    return filteredContractors;
} 

export const prepareContractorsEstimateWithScope = (contractors, projectItems, payments, considerEstimationFlags = true, considerContractorsWithoutEstimation = true) => {
    const treeResult = [{ key: '', children: [] }];
    const filteredContractors = filterContractorsDependOnRole(contractors, considerContractorsWithoutEstimation);
    const contractorsArray = convertObjectCollectionToArrayForTreeTable(filteredContractors);


    filteredContractors &&
        contractorsArray.forEach((contractor) => {
            const element = filteredContractors[contractor.key];
            element.key = contractor.key;
            element.isContractor = true;
            const paymentIdsAssignedToPurchases = [];
            Object.keys(projectItems)
                .filter((key) => projectItems[key].contractorId === '' && projectItems[key]?.costType === COST_TYPES.MATERIALS)
                .forEach(key => {
                    if (projectItems[key].paymentId) {
                        paymentIdsAssignedToPurchases.push(projectItems[key].paymentId);
                    }
                });
            let children = Object.keys(projectItems)
                .filter((key) => projectItems[key].contractorId === element.email && projectItems[key]?.costType === COST_TYPES.LABOR)
                .filter((key) => considerEstimationFlags ? projectItems[key].completed === true && projectItems[key].accepted === true : projectItems[key].completed === true)
                .reduce((obj, key) => {
                    obj[key] = projectItems[key];
                    obj[key].isProjectItem = true;
                    obj[key].contractorInvited = contractor.contractorInvited;
                    obj[key].contractorWithEstimationLink = contractor.contractorWithEstimationLink;
                    obj[key].userContractorExists = contractor.userContractorExists;
                    return obj;
                }, {});
            let completedChildrenExists = Object.keys(projectItems)
                .filter((key) => projectItems[key].contractorId === element.email && projectItems[key]?.costType === COST_TYPES.LABOR)
                .filter((key) => projectItems[key].completed === true).length > 0;
            if (Object.keys(children).length > 0) {
                let result = processChildrenForCategories(children, element.key, element.email);
                if (result.length > 0) {
                    result = result.map(obj => ({
                        ...obj,
                        contractorInvited: contractor.contractorInvited,
                        contractorWithEstimationLink: contractor.contractorWithEstimationLink,
                        userContractorExists: contractor.userContractorExists
                    })); 
                    let acceptedQuantity = 0;
                    let allItems = 0;
                    result.forEach((value) => {
                        acceptedQuantity += value.children.filter(x => x.accepted === true).length;
                        allItems += value.children.length;
                    });
                    element.accepted = acceptedQuantity === allItems;
                    result = result.map(item => ({ ...item, accepted: element.accepted }));
                    element.children = result;
                }
            } else {
                delete element['children'];
                element.hasNoChildren = true;
                element.accepted = true;
            }
            if (element.children === undefined) {
                element.comparative = undefined;
            }
            const paid = Object.keys(payments)
                .filter(
                    (key) =>
                        payments[key].contractorId === element.email &&
                        element.key !== 'notAssigned' &&
                        payments[key].status === PAYMENT_STATUSES.PAID &&
                        !paymentIdsAssignedToPurchases.includes(key)
                )
                .map((key) => payments[key].value)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            element.paid = paid;
            element.cost =
                element.children?.length > 0
                    ? element.children
                          .map((x) => getCostValue(x))
                          .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                    : 0;
            element.balance = element.cost - element.paid;
            if (!considerEstimationFlags) {
                treeResult[0].children.push(element);
            } else {
                if (!(completedChildrenExists && element.children === undefined)) {
                    treeResult[0].children.push(element);
                }
            }
        });
    return {
        tree: treeResult[0].children,
        list: projectItems,
        contractorsAssigned: filteredContractors.notAssigned === undefined ? true : false,
        totalSum: treeResult[0].children
            .map((x) => x.cost)
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
    };
}

const processChildrenForContractors = (children, columnKey, sortFunc = () => sortByFirstLetter()) => {
    const contractorsWithItems = new Map();
    Object.keys(children).forEach(key => {
        const contractorEmail = children[key][columnKey];
        if (contractorsWithItems.has(contractorEmail)) {
            const value = contractorsWithItems.get(contractorEmail).concat([{...children[key], key: key}]);
            contractorsWithItems.set(contractorEmail, value);
        } else {
            contractorsWithItems.set(contractorEmail, [{...children[key], key: key}]);
        }
    });
    contractorsWithItems.forEach((value) => {
        value.sort(sortFunc());
    });
    return contractorsWithItems;
}

const processChildrenForCategories = (children, parentElementKey = undefined, contractorId = '') => {
    const categoriesWithItems = new Map();
    Object.keys(children).forEach(key => {
        const category = children[key].categoryId;
        if (categoriesWithItems.has(category)) {
            const value = categoriesWithItems.get(category).concat([{...children[key], key: key}]);
            categoriesWithItems.set(category, value);
        } else if (category !== undefined && category !== null) {
            categoriesWithItems.set(category, [{...children[key], key: key}]);
        }
    });
    categoriesWithItems.forEach((value) => {
        value.sort(sortByFirstLetter());
    });
    const childrenResult = Array.from(categoriesWithItems, ([name, value]) => ({ name, key: parentElementKey !== undefined ? name + parentElementKey : name, isCategory: true, children: value, email: contractorId, cost: calculateSumCost(value), balance: calculateSumBalance(value) }));
    childrenResult.sort(sortByFirstLetter());
    return childrenResult;
}

const calculateSumCost = (input) => {
    return input.map(x => getCostValue(x)).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

const calculateSumBalance = (input) => {
    return input.map(x => x.balance).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

const getCostValue = (record) => {
    return record.sumCost !== undefined ? record.sumCost : record.cost;
}

export const orderRowsByCostType = (children, costType) => {
    return children.filter(x => x.costType === costType).sort((a, b) => {
        if (a.position === null || a.hasOwnProperty('position') === false) {
            return 1;
        }

        if (b.position === null || b.hasOwnProperty('position') === false) {
            return -1;
        }

        if (a.position === b.position) {
            return 0;
        }

        return a.position < b.position ? -1 : 1;
    });
}

export const orderRowsByPosition = (children) => {
    return children.sort((a, b) => {
        if (a.position === null || a.hasOwnProperty('position') === false) {
            return 1;
        }

        if (b.position === null || b.hasOwnProperty('position') === false) {
            return -1;
        }

        if (a.position === b.position) {
            return 0;
        }

        return a.position < b.position ? -1 : 1;
    });
}

export const prepareEstimateStructure = (list) => {
    let inputStructureForTree = undefined;
    const treeResult = [{ key: '', children: [] }];
    function copyData(input) {
        inputStructureForTree = cloneDeep(input);
    }
    const arrayToSort = [];
    const sortedKeys = [];
    Object.keys(list).map((id) => {
        arrayToSort.push({ ...list[id], key: id}  );
    });
    arrayToSort.sort(sortCategoriesLast());
    arrayToSort.forEach(item => {
        sortedKeys.push(item.key);
    });
    list && copyData(list);
    list && inputStructureForTree && findItemsOnLevels([''], inputStructureForTree, treeResult[0]);
    list &&
        inputStructureForTree &&
        sortedKeys.forEach((id) => {
            const element = inputStructureForTree[id];
            let sumCost = element?.parentProjectItemId === '' && !hasChildren(element) ? sumCosts(element, true, true) : hasChildren(element) ? sumCosts(element, true, false) : sumCosts(element, true, true);
            let optionalCostsValue = element?.parentProjectItemId === '' && !hasChildren(element) ? sumCosts(element, false, true) : hasChildren(element) ? sumCosts(element, false, false) : sumCosts(element, false, true);
            if (hasChildren(element)) {
                element.children.sort(sortByFirstLetter());
            }
            inputStructureForTree[id].sumCost = sumCost;
            inputStructureForTree[id].optionalCosts = optionalCostsValue;     
        });
    return { treeResult: treeResult, inputStructureForTree: inputStructureForTree};
}

export const prepareNetworkContractors = (networkContractors, project) => {
    const treeResult = [{ key: '', children: [] }];
    if (networkContractors) {
        let filtered = Object.keys(networkContractors)
            .reduce((obj, key) => {
                obj[key] = networkContractors[key];
                obj[key].isNetworkContractor = true;
                obj[key].projectId = project.id;
                obj[key].projectDescription = project.description;
                return obj;
            }, {});
        Object.keys(filtered)
            .map((id) => {
                treeResult[0].children.push({ ...filtered[id], key: id });
            });
    }
    treeResult[0].children.sort(sortByStarratingAndCompanyName());
    return { tree: treeResult[0].children, list: networkContractors, totalSum: undefined };
}

export const prepareNetworkContractorsForModal = (networkContractors, project) => {
    const mapResult = new Map();
    const inputSet = networkContractors.has(Number(project.address)) ? networkContractors.get(Number(project.address)) : []
    const result = prepareNetworkContractors(inputSet, project);
    mapResult.set('', result.tree);
    return mapResult;
};

export const sortByFirstLetter = (ascending = true) => {
    return function (a, b) {
      if (ascending) {
          return a.name[0] < b.name[0] ? -1 : 1;
      }
      return a.name[0] < b.name[0] ? 1 : -1;
    };
}

export const sortByStarratingAndCompanyName = () => {
    return function (a, b) {
        if (b.starrating !== a.starrating) {
            return b.starrating - a.starrating;
        }
        return a.companyName.localeCompare(b.companyName);
    };
}

export const sortByDate = (ascending = true) => {
    return function (a, b) {
        if (ascending) {
            return new Date(a?.date) < new Date(b?.date) ? -1 : 1;
        }
        return new Date(a?.date) < new Date(b?.date) ? 1 : -1;
      };
}

export const sortCategoriesLast = (ascending = true) => {
    return function (a, b) {
      if (a.parentProjectItemId === b.parentProjectItemId) {
          return 0;
      }
      if (a.parentProjectItemId === '') {
          return 1;
      }
      if (b.parentProjectItemId === '') {
          return -1;
      }

      if (ascending) {
          return a < b ? -1 : 1;
      }
      return a < b ? 1 : -1;
    };
  }

const hasChildren = (element) => {
    if (Array.isArray(element?.children) && element?.children.length > 0) {
        return true;
    } else {
        return false;
    }
}

export const getFirstNonNullValue =(array) => {
    return array.find(element => element !== undefined && element !== null);
}

export const sumCosts = (element, inScope, inclueCostFromParentInCaseOfZero = true) => {
    const baseEstimateFields = [element?.sumCost, element?.cost];
    const optionalCostsFields = [element?.optionalCosts, element?.cost];
    if (!hasChildren(element)) {
        return element?.inScope === inScope ? getFirstNonNullValue(inScope ? baseEstimateFields : optionalCostsFields) : 0;
    } else {
        var result = 0;
        for (var i = 0; i < element.children.length; i++) {
            result += sumCosts(element.children[i], inScope, inclueCostFromParentInCaseOfZero);
        }
        if (inclueCostFromParentInCaseOfZero && result === 0 && element.inScope === inScope) {
            result +=  getFirstNonNullValue(inScope ? baseEstimateFields : optionalCostsFields);
        }
        return result;
    }
};

function searchTree(element, elementToAdd, inputList) {
    if (element.key === elementToAdd.parentProjectItemId) {
        if (element.children === undefined) {
            element.children = [];
        }
        return element;
    } else if (element.children != null) {
        var i;
        var result = null;
        for (i = 0; result == null && i < element.children.length; i++) {
            result = searchTree(element.children[i], elementToAdd, inputList);
        }
        return result;
    }
}

export const findCategoryWithAllChildrenIds = (input) => {
    const result = [];
    if (
        input.children === null ||
        input.children === undefined ||
        (Array.isArray(input.children) && input.children.length === 0)
    ) {
        return [input.key];
    } else {
        for (var i = 0; i < input.children.length; i++) {
            findCategoryWithAllChildrenIds(input.children[i]).forEach((item) => result.push(item));
        }
        result.push(input.key);
    }
    return result;
};

export const findItemsOnLevels = (input, inputList, result) => {
    const ids = [];
    const elements = {};
    Object.keys(inputList).map((id, lp) => {
        if (input.includes(inputList[id].parentProjectItemId)) {
            elements[id] = inputList[id];
            ids.push(id);
        }
    });
    if (ids.length > 0) {
        ids.forEach((id) => {
            const elementToAdd = inputList[id];
            elementToAdd['key'] = id;
            searchTree(result, elementToAdd, inputList).children.push(elementToAdd);
        });
        return findItemsOnLevels(ids, inputList, result);
    } else {
        return;
    }
};

export const getProjectItemsBasedOnScope = (input, inScopeMode) => {
    return inScopeMode ? Object.fromEntries(Object.entries(input).filter(([key]) => input[key].parentProjectItemId !== '')) : input; 
}

export const getSumCostFromChildren = (children, projectItemId) => {
    return children.filter(child => child.key === projectItemId)
    .map(child => child.sumCost)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

export const getTreeResult = (treeResult, inScopeMode, currentCategoryId) => {
    const result = inScopeMode ? treeResult[0]?.children.find(x => x.key === currentCategoryId)?.children : treeResult[0]?.children;
    return  result ? result : [];
}

export const calculateTotalSum = (list, treeResult, costTypeFilter, inScopeMode, currentCategoryId) => {
    let totalSum = 0;
    list &&
    Object.keys(getProjectItemsBasedOnScope(list, inScopeMode)).map((projectItemId) => {
        if (costTypeFilter) {
            totalSum += costTypeFilter === getProjectItemsBasedOnScope(list, inScopeMode)[projectItemId].costType ? getSumCostFromChildren(getTreeResult(treeResult, inScopeMode, currentCategoryId), projectItemId) : 0;
        } else {
            totalSum += getSumCostFromChildren(getTreeResult(treeResult, inScopeMode, currentCategoryId), projectItemId);
        }
    });
    return totalSum;
}

export const getCostsDataForProject = (projectItems, projectPayments, contractors) => {
    const contractorEmails = Object.keys(contractors)
        .filter((key) => contractors[key].email !== '')
        .map((key) => contractors[key].email);
    const paymentsMade = Object.keys(projectPayments)
        .filter((key) => projectPayments[key].status === PAYMENT_STATUSES.PAID)
        .filter((key) => projectPayments[key].contractorId !== '' ? contractorEmails.includes(projectPayments[key].contractorId) : true)
        .filter((key) => isLoggedInUserAContractor() ? projectPayments[key]?.contractorId === getLoggedInUserEmail() : true)
        .map((key) => projectPayments[key].value)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const pendingCosts = Object.keys(projectPayments)
        .filter((key) => projectPayments[key].status === PAYMENT_STATUSES.PENDING)
        .filter((key) => isLoggedInUserAContractor() ? projectPayments[key]?.contractorId === getLoggedInUserEmail() : true)
        .filter((key) => projectPayments[key].contractorId !== '' ? contractorEmails.includes(projectPayments[key].contractorId) : true)
        .map((key) => projectPayments[key].value)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const totalCostsProjectItems = Object.keys(projectItems)
        .filter((key) => isLoggedInUserAContractor() ? projectItems[key]?.contractorId === getLoggedInUserEmail() : true)
        .filter((key) => projectItems[key]?.accepted === true && projectItems[key]?.completed === true)
        .map((key) => projectItems[key].cost)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const totalPlannedBudget = totalCostsProjectItems;
    const balanceOverrun = totalPlannedBudget - paymentsMade;
    return {
        pendingCosts: pendingCosts,
        paidCosts: paymentsMade,
        balanceOverrun: balanceOverrun,
        totalPlannedBudget: totalPlannedBudget,
    };
}