const contractorActions = {

    //CONTRACTORS_OF_PROJECT
    LOAD_CONTRACTORS_OF_PROJECT_START: "LOAD_CONTRACTORS_OF_PROJECT_START",
    LOAD_CONTRACTORS_OF_PROJECT_SUCCESS: "LOAD_CONTRACTORS_OF_PROJECT_SUCCESS",
    LOAD_CONTRACTORS_OF_PROJECT_FAILED: "LOAD_CONTRACTORS_OF_PROJECT_FAILED",

    //CREATE_CONTRACTOR
    CREATE_CONTRACTOR_START: "CREATE_CONTRACTOR_START",
    CREATE_CONTRACTOR_SUCCESS: "CREATE_CONTRACTOR_SUCCESS",
    CREATE_CONTRACTOR_FAILED: "CREATE_CONTRACTOR_FAILED",

    //EDIT_CONTRACTOR
    EDIT_CONTRACTOR_START: "EDIT_CONTRACTOR_START",
    EDIT_CONTRACTOR_SUCCESS: "EDIT_CONTRACTOR_SUCCESS",
    EDIT_CONTRACTOR_FAILED: "EDIT_CONTRACTOR_FAILED",

    //DELETE_CONTRACTOR
    DELETE_CONTRACTOR_START: 'DELETE_CONTRACTOR_START',
    DELETE_CONTRACTOR_SUCCESS: 'DELETE_CONTRACTOR_SUCCESS',
    DELETE_CONTRACTOR_FAILED: 'DELETE_CONTRACTOR_FAILED',

  //INVITE_CONTRACTOR
  INVITE_CONTRACTOR_START: 'INVITE_CONTRACTOR_START',
  INVITE_CONTRACTOR_SUCCESS: 'INVITE_CONTRACTOR_SUCCESS',
  INVITE_CONTRACTOR_FAILED: 'INVITE_CONTRACTOR_FAILED',

  //SEND_REQUEST_ESTIMATION
  SEND_REQUEST_ESTIMATION_START: 'SEND_REQUEST_ESTIMATION_START',
  SEND_REQUEST_ESTIMATION_SUCCESS: 'SEND_REQUEST_ESTIMATION_SUCCESS',
  SEND_REQUEST_ESTIMATION_FAILED: 'SEND_REQUEST_ESTIMATION_FAILED',

  //MAKE_COMPARATIVE_CONTRACTOR
  MAKE_COMPARATIVE_CONTRACTOR_START: 'MAKE_COMPARATIVE_CONTRACTOR_START',
  MAKE_COMPARATIVE_CONTRACTOR_SUCCESS: 'MAKE_COMPARATIVE_CONTRACTOR_SUCCESS',
  MAKE_COMPARATIVE_CONTRACTOR_FAILED: 'MAKE_COMPARATIVE_CONTRACTOR_FAILED',

    //CLEAR_CONTRACTORS_FLAGS
    CLEAR_CONTRACTORS_FLAGS: 'CLEAR_CONTRACTORS_FLAGS',

  //FIND_CONTRACTOR
  FIND_CONTRACTOR_START: 'FIND_CONTRACTOR_START',
  FIND_CONTRACTOR_SUCCESS: 'FIND_CONTRACTOR_SUCCESS',
  FIND_CONTRACTOR_FAILED: 'FIND_CONTRACTOR_FAILED',

    // Contractor Actions
  createLoadContractorsOfProjectActionStart: (projectId) => {
    return {
      type: contractorActions.LOAD_CONTRACTORS_OF_PROJECT_START,
      payload: projectId,
    };
  },
  createLoadContractorsOfProjectActionSuccess: (contractors) => {
    return {
      type: contractorActions.LOAD_CONTRACTORS_OF_PROJECT_SUCCESS,
      payload: contractors,
    };
  },
  createLoadContractorsOfProjectActionFailed: (contractorsError) => {
    return {
      type: contractorActions.LOAD_CONTRACTORS_OF_PROJECT_FAILED,
      payload: contractorsError,
    };
  },
  createCreateContractorActionStart: (contractor) => {
    return {
      type: contractorActions.CREATE_CONTRACTOR_START,
      payload: contractor,
    };
  },
  createCreateContractorActionSuccess: (contractorsSuccess) => {
    return {
      type: contractorActions.CREATE_CONTRACTOR_SUCCESS,
      payload: contractorsSuccess,
    };
  },
  createCreateContractorActionFailed: (contractorsError) => {
    return {
      type: contractorActions.CREATE_CONTRACTOR_FAILED,
      payload: contractorsError,
    };
  },
  createEditContractorActionStart: (contractor) => {
    return {
      type: contractorActions.EDIT_CONTRACTOR_START,
      payload: contractor,
    };
  },
  createEditContractorActionSuccess: (contractorsSuccess) => {
    return {
      type: contractorActions.EDIT_CONTRACTOR_SUCCESS,
      payload: contractorsSuccess,
    };
  },
  createEditContractorActionFailed: (contractorsError) => {
    return {
      type: contractorActions.EDIT_CONTRACTOR_FAILED,
      payload: contractorsError,
    };
  },
  createDeleteContractorActionStart: (payload) => {
    return {
      type: contractorActions.DELETE_CONTRACTOR_START,
      payload: payload,
    };
  },
  createDeleteContractorActionSuccess: (contractorsSuccess) => {
    return {
      type: contractorActions.DELETE_CONTRACTOR_SUCCESS,
      payload: contractorsSuccess,
    };
  },
  createDeleteContractorActionFailed: (contractorsError) => {
    return {
      type: contractorActions.DELETE_CONTRACTOR_FAILED,
      payload: contractorsError,
    };
  },
  createInviteContractorStart: (payload) => {
    return {
      type: contractorActions.INVITE_CONTRACTOR_START,
      payload: payload,
    };
  },
  createInviteContractorSuccess: (contractorsSuccess) => {
    return {
      type: contractorActions.INVITE_CONTRACTOR_SUCCESS,
      payload: contractorsSuccess,
    };
  },
  createInviteContractorFailed: (contractorsError) => {
    return {
      type: contractorActions.INVITE_CONTRACTOR_FAILED,
      payload: contractorsError,
    };
  },
  createFindContractorStart: (payload) => {
    return {
      type: contractorActions.FIND_CONTRACTOR_START,
      payload: payload,
    };
  },
  createFindContractorSuccess: (contractorsSuccess) => {
    return {
      type: contractorActions.FIND_CONTRACTOR_SUCCESS,
      payload: contractorsSuccess,
    };
  },
  createFindContractorFailed: (contractorsError) => {
    return {
      type: contractorActions.FIND_CONTRACTOR_FAILED,
      payload: contractorsError,
    };
  },
  createSendRequestEstimationStart: (payload) => {
    return {
      type: contractorActions.SEND_REQUEST_ESTIMATION_START,
      payload: payload,
    };
  },
  createSendRequestEstimationSuccess: (contractorsSuccess) => {
    return {
      type: contractorActions.SEND_REQUEST_ESTIMATION_SUCCESS,
      payload: contractorsSuccess,
    };
  },
  createSendRequestEstimationFailed: (contractorsError) => {
    return {
      type: contractorActions.SEND_REQUEST_ESTIMATION_FAILED,
      payload: contractorsError,
    };
  },
  createMakeComparativeContractorActionStart: (projectitems) => {
    return {
      type: contractorActions.MAKE_COMPARATIVE_CONTRACTOR_START,
      payload: projectitems,
    };
  },
  createMakeComparativeContractorActionSuccess: (contractorsSuccess) => {
    return {
      type: contractorActions.MAKE_COMPARATIVE_CONTRACTOR_SUCCESS,
      payload: contractorsSuccess,
    };
  },
  createMakeComparativeContractorActionFailed: (contractorsError) => {
    return {
      type: contractorActions.MAKE_COMPARATIVE_CONTRACTOR_FAILED,
      payload: contractorsError,
    };
  },
  clearContractorsFlags: (payload) => {
    return {
      type: contractorActions.CLEAR_CONTRACTORS_FLAGS,
      payload: payload,
    };
  },
  };
  
export default contractorActions;