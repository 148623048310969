import { all, takeEvery, put, call } from 'redux-saga/effects';
import { rsf, db } from '@iso/lib/firebase/firebase';
import usersActions from './actions';
import { loadStripe } from '@stripe/stripe-js';
import firebase from "firebase/app";
import "firebase/auth";

const auth = firebase.auth();

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function* findUserPaymentSaga({ payload }) {
    try {
        const userResult = yield call(getUserByEmail, { email: payload.email });
        yield put(
            usersActions.createFindUserPaymentSuccess({
                usersSuccess: 'Payment data for user found',
                selectedUserPayment: {
                    email: payload.email,
                    expirationDate: [null, undefined].includes(userResult.expirationDate) ? undefined : userResult.expirationDate,
                    paymentType: [null, undefined].includes(userResult.paymentType) ? undefined : userResult.paymentType
                }
            })
        );
    } catch (error) {
        yield put(
            usersActions.createFindUserPaymentFailed({
                usersError: error,
            })
        );
    }
}

function* getIdToken() {
    try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
            throw new Error("User not authenticated");
        }
        const idToken = yield call([currentUser, currentUser.getIdToken]);
        return idToken;
    } catch (error) {
        console.error("Error getting ID token:", error);
        throw error;
    }
}

function* processCheckoutSession() {
    try {
        const stripe = yield call(() => stripePromise);
        const idToken = yield call(getIdToken);
        const response = yield call(fetch, process.env.REACT_APP_CHECKOUT_SESSION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            },
        });
        const { sessionId } = yield call([response, 'json']);
        yield call([stripe, 'redirectToCheckout'], { sessionId: sessionId });
    } catch (error) {
        yield put(
            usersActions.createFindUserPaymentFailed({
                usersError: { message: 'Cannot proceed with payment' },
            })
        );
        yield put(
            usersActions.createFindUserPaymentFailed({
                usersError: '',
            })
        );
    }
}

function* processSubscriptionSession() {
    try {
        const stripe = yield call(() => stripePromise);
        const idToken = yield call(getIdToken);
        const response = yield call(fetch, process.env.REACT_APP_SUBSCRIPTION_SESSION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            },
        });
        const { sessionId } = yield call([response, 'json']);
        yield call([stripe, 'redirectToCheckout'], { sessionId: sessionId });
    } catch (error) {
        yield put(
            usersActions.createFindUserPaymentFailed({
                usersError: { message: 'Cannot proceed with payment' },
            })
        );
        yield put(
            usersActions.createFindUserPaymentFailed({
                usersError: '',
            })
        );
    }
}

function* getUserByEmail(payload) {
    let userResult = undefined;
    const collectionReference = db
        .collection('users')
        .where('email', '==', payload.email);
    const usersSnapshot = yield call(rsf.firestore.getCollection, collectionReference);
    usersSnapshot.forEach((user) => {
        userResult = { ...user.data(), key: user.id };
    });
    return userResult;
}


export default function* usersSaga() {
    yield all([
        takeEvery(usersActions.FIND_USER_PAYMENT_START, findUserPaymentSaga),
        takeEvery(usersActions.CHECKOUT_SESSION_START, processCheckoutSession),
        takeEvery(usersActions.SUBSCRIPTION_SESSION_START, processSubscriptionSession)
    ]);
}
