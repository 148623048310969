import { loadState } from '@iso/lib/helpers/localStorage';

export const isConnectLater = () => {
    return loadState('connectLater') ?? false;
}

export const isLoggedInUserAContractor = () => {
    return loadState('user')?.role === 'contractor';
};

export const isLoggedInUserAHomeOwner = () => {
    return loadState('user')?.role === 'owner';
};

export const getRoleCurrentUser = () => {
    return loadState('user') ? loadState('user')?.role : '';
}

export const getOvverideEmailPassword = () => {
    return loadState('ovverideEmailPassword');
}

export const getOverridePaymentAccessPassword = () => {
    return loadState('overridePaymentAccessPassword');
}

export const getLoggedInUserId = () => {
    return loadState('user')?.uid;
};

export const getLoggedInUser = () => {
    return loadState('user');
};

export const getLoggedInUserEmail = () => {
    return loadState('user')?.email;
};

export const getLoggedInUserFullName = () => {
    return loadState('user')?.firstname + ' ' + loadState('user')?.lastname;
}
