import networkContractorsActions from "./actions";

const initialState = {
    networkContractorsList: new Map(),
    networkContractorsError: '',
    networkContractorsSuccess: '',
    isLoadingNetworkContractors: false
};

export default function networkContractorsReducer(state = initialState, action) {
    switch (action.type) {
        case networkContractorsActions.LOAD_NETWORK_CONTRACTORS_START:
            return {
                ...state,
                ...action.payload,
                isLoadingNetworkContractors: true
            };
        case networkContractorsActions.LOAD_NETWORK_CONTRACTORS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isLoadingNetworkContractors: false
            };
        case networkContractorsActions.LOAD_NETWORK_CONTRACTORS_FAILED:
            return {
                ...state,
                ...action.payload,
                isLoadingNetworkContractors: false
            };
        default:
            return state;
    }
}