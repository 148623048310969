const networkContractorActions = {

    //LOAD_NETWORK_CONTRACTORS
    LOAD_NETWORK_CONTRACTORS_START: "LOAD_NETWORK_CONTRACTORS_START",
    LOAD_NETWORK_CONTRACTORS_SUCCESS: "LOAD_NETWORK_CONTRACTORS_SUCCESS",
    LOAD_NETWORK_CONTRACTORS_FAILED: "LOAD_NETWORK_CONTRACTORS_FAILED",

    // Network Contractors Actions
    createLoadNetworkContractorsActionStart: (payload) => {
        return {
            type: networkContractorActions.LOAD_NETWORK_CONTRACTORS_START,
            payload: payload,
        };
    },
    createLoadNetworkContractorsActionSuccess: (networkContractorsSuccess) => {
        return {
            type: networkContractorActions.LOAD_NETWORK_CONTRACTORS_SUCCESS,
            payload: networkContractorsSuccess,
        };
    },
    createLoadNetworkContractorsActionFailed: (networkContractorsError) => {
        return {
            type: networkContractorActions.LOAD_NETWORK_CONTRACTORS_FAILED,
            payload: networkContractorsError,
        };
    }
};

export default networkContractorActions;