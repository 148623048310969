import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import { rsf, db } from '@iso/lib/firebase/firebase';
import { cloneNetworkContractor } from '@iso/lib/remod-helpers/globalHelpers';
import networkContractorsActions from './actions';

const getNetworkContractors = (state) => state.networkContractors;

function* loadNetworkContractors({ payload }) {
    const { networkContractorsList } = yield select(getNetworkContractors);
    let networkContractors = {};
    const { zipCode } = payload;
    try {
        let networkContractorsQueryReference = db.collection("networkContractors")
            .where('allZipCodes', 'array-contains', String(zipCode));
        const networkContractorsSnapshot = yield call(
            rsf.firestore.getCollection,
            networkContractorsQueryReference
        );
        networkContractorsSnapshot.forEach((networkContractor) => {
            networkContractors[networkContractor.id] = {
                ...cloneNetworkContractor(networkContractor.data())
            };
        });
        const updatedMap = new Map(networkContractorsList);
        updatedMap.set(zipCode, networkContractors);
        yield put(
            networkContractorsActions.createLoadNetworkContractorsActionSuccess({
                networkContractorsList: updatedMap
            })
        );
    } catch (error) {
        yield put(
            networkContractorsActions.createLoadNetworkContractorsActionFailed({
                networkContractorsError: error,
            })
        );
    }
}


export default function* networkContractorsSaga() {
    yield all([
        takeEvery(networkContractorsActions.LOAD_NETWORK_CONTRACTORS_START, loadNetworkContractors),
    ]);
}
